@use '@/styles/utils/mixins.scss' as *;

.ctaBannerWrapper {
  margin-top: 7.5rem;
  @include tab() {
    margin-top: 5.125rem;
  }

  .ctaBannerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6.25rem;
  }

  .ctaBannerContent {
    display: flex;
    flex-direction: column;
    gap: 3.75rem;
    align-items: center;
    max-width: 68rem;
    width: 100%;
    margin: 0 auto;
  }

  .ctaBannerHeadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  .offeringText {
    color: $primary;
    text-align: center;
    width: 100%;
    display: block;
    position: relative;
    white-space: nowrap;
    height: 4.875rem;
    @include tab() {
      height: 2.625rem;
    }

    .offeringTextItem {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      transition: opacity 0.5s ease forwards;

      &.active {
        opacity: 1;
      }
    }
  }

  .description {
    text-align: center;
    margin: 0 auto;
    max-width: 47.5rem;
    line-height: 1.5;
    font-size: 1rem;
    font-weight: 400;
  }

  .bannerImageContainer {
    position: relative;
    overflow: hidden;
    border-radius: 1.25rem;
    width: 100%;
    height: 33.75rem;
    @include tab() {
      margin: 0 -1.875rem;
      width: calc(100% + 3.75rem);
    }
    @include mob() {
      height: 26rem;
    }

    .bannerImageWrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      transition: all 0.1s ease;
    }

    .bannerImage {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@keyframes textSwitch {
  0%,
  25% {
    opacity: 0;
  }
  25.01%,
  50% {
    opacity: 1;
  }
  50.01%,
  100% {
    opacity: 0;
  }
}
